@import '~antd/dist/antd.less';


.App {
  font-family: 'Nunito Sans', sans-serif;
}

.custom-td, .custom-th {

  text-align: left;
  padding: 16px;
}

.custom-tr:nth-child(even) {
  background-color: #F4F2FF;
}

.visible-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1; 
// }
 
/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #009688; 
//   height: 20px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555; 
// }

// table {
//   font-family: 'Nunito Sans', sans-serif;
//   border-collapse: collapse;
//   width: 100%;
// }


// table td, table th {
//   border-bottom: 1px solid #E8EAED;
//   text-align: left;
//   // padding: 2rem;
//   font-weight: 100;
//   font-size: 14px;
//   color: #6C757D;
// }

// table td {
//   font-weight: 600;
// }


table tr:nth-child(even){background-color: #FAFAFB;}


@primary-color: #009688;@primary-1: rgba(20, 182, 167, 0.1);