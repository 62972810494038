.tender-box {
    height: 60vh;
    overflow: scroll;
}
.tender-box::-webkit-scrollbar {
    width: 4px;
}
  
.tender-box::-webkit-scrollbar-thumb {
    background: #009688; 
    border-radius: 10px;
}
  